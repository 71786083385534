import React, { useRef, useState, useEffect } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
function Form(props) {
  const { type, service, shipment, setOpen } = props;
  console.log(type);

  if (type === "inquiry") {
    const CustomDropdown = ({ options, placeholder, defaultOption }) => {
      const [isOpen, setIsOpen] = useState(false);
      const [selectedOption, setSelectedOption] = useState(
        defaultOption || null
      );
      const dropdownRef = useRef(null);

      // Toggle the dropdown
      const toggleDropdown = (e) => {
        e.preventDefault(); // Prevent form submission
        setIsOpen(!isOpen);
      };

      // Close dropdown if clicked outside
      useEffect(() => {
        const handleClickOutside = (event) => {
          if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
          ) {
            setIsOpen(false);
          }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () =>
          document.removeEventListener("mousedown", handleClickOutside);
      }, []);

      // Handle selecting an option
      const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
      };

      return (
        <div className="custom-dropdown" ref={dropdownRef}>
          <button
            type="button"
            className="dropdown-btn"
            onClick={toggleDropdown}
          >
            {selectedOption || placeholder || "Select an option"}
            <span className="dropdown-arrow">▼</span>
          </button>
          {isOpen && (
            <div className="dropdown-content">
              {options.map((option, index) => (
                <div
                  key={index}
                  className="dropdown-option"
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
      );
    };
    return (
      <div className="form-container">
        <CancelRoundedIcon
          className="close"
          fontSize=""
          onClick={() => {
            setOpen(false);
          }}
        />
        <h4 className="section-title black center">
          Submit <span className="orange">Your Inquiry</span>
        </h4>
        <form
          className="basket-form"
          name="eform"
          // action="/confirmation.html"
          // method="POST"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="Enquiries" />

          <div>
            <label>Name</label>
            <input type="text" name="name" />
          </div>
          <div>
            <label>Company</label>
            <input type="text" name="company" />
          </div>
          <div>
            <label>Phone number</label>
            <input type="text" name="phone" />
          </div>
          <div>
            <label>E-mail</label>
            <input type="email" name="email" />
          </div>
          <div>
            <label className="message-label">Message</label>
            <textarea name="message"></textarea>
          </div>
          <div>
            <label className="message-label">Inquiry</label>
            <CustomDropdown
              options={[
                "Air Freight Solutions",
                "Sea Freight Solutions",
                "Charter Air Freight Services",
                "Customs Clearance",
                "Cold Chain Management",
                "Warehousing",
                "Road and Railway Transport",
              ]}
              placeholder="Please Choose Your Inquiry Topic"
              defaultOption={service}
            />
          </div>
          <br />
          <button className="send-button">Send Message</button>
        </form>
      </div>
    );
  }
  if (type === "shipment") {
    const CustomDropdown = ({ options, placeholder, defaultOption }) => {
      const [isOpen, setIsOpen] = useState(false);
      const [selectedOption, setSelectedOption] = useState(
        defaultOption || null
      );
      const dropdownRef = useRef(null);

      // Toggle the dropdown
      const toggleDropdown = (e) => {
        e.preventDefault(); // Prevent form submission
        setIsOpen(!isOpen);
      };

      // Close dropdown if clicked outside
      useEffect(() => {
        const handleClickOutside = (event) => {
          if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
          ) {
            setIsOpen(false);
          }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () =>
          document.removeEventListener("mousedown", handleClickOutside);
      }, []);

      // Handle selecting an option
      const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
      };

      return (
        <div className="custom-dropdown" ref={dropdownRef}>
          <button
            type="button"
            className="dropdown-btn"
            onClick={toggleDropdown}
          >
            {selectedOption || placeholder || "Select an option"}
            <span className="dropdown-arrow">▼</span>
          </button>
          {isOpen && (
            <div className="dropdown-content">
              {options.map((option, index) => (
                <div
                  key={index}
                  className="dropdown-option"
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
      );
    };
    return (
      <div className="form-container">
        <CancelRoundedIcon
          className="close"
          fontSize=""
          onClick={() => {
            setOpen(false);
          }}
        />
        <h4 className="section-title black center">
          Ship It Today <span className="orange">- Book Now!</span>
        </h4>
        <form
          className="basket-form"
          name="eform"
          action="/confirmation.html"
          method="POST"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="Enquiries" />

          <div>
            <label>Name</label>
            <input type="text" name="name" />
          </div>
          <div>
            <label>Company</label>
            <input type="text" name="company" />
          </div>
          <div>
            <label>Phone number</label>
            <input type="text" name="phone" />
          </div>
          <div>
            <label>E-mail</label>
            <input type="email" name="email" />
          </div>
          <div>
            <label className="message-label">Message</label>
            <textarea name="message"></textarea>
          </div>
          <div>
            <label className="message-label">Shipment</label>
            <CustomDropdown
              options={[
                "Mango",
                "Avocado",
                "Pineapple",
                "Vegetables",
                "Flowers",
                "Meat",
                "Dangerous Goods (DGR Cargo)",
                "Pharma & Healthcare",
                "Humanitarian Support Logistics",
                "Machinery & Equipment",
                "Critical Time Shipments",
                "Fashion & Textile Shipments",
                "Live Animals & Live Seafood",
              ]}
              placeholder="Please Choose Your Inquiry Topic"
              defaultOption={shipment}
            />
          </div>
          <br />
          <button className="send-button">Send Message</button>
        </form>
      </div>
    );
  }

  // Similar structure for shipment form type

  return (
    <div>
      <h4 className="section-title black">
        Have Questions? <span className="orange">Let's Chat!</span>
      </h4>
      <form
        className="basket-form"
        name="eform"
        action="/confirmation.html"
        method="POST"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="Enquiries" />

        <div>
          <label>Name</label>
          <input type="text" name="name" />
        </div>
        <div>
          <label>Company</label>
          <input type="text" name="company" />
        </div>
        <div>
          <label>Phone number</label>
          <input type="text" name="phone" />
        </div>
        <div>
          <label>E-mail</label>
          <input type="email" name="email" />
        </div>
        <div>
          <label className="message-label">Message</label>
          <textarea name="message"></textarea>
        </div>
        <br />
        <button className="send-button">Send Message</button>
      </form>
    </div>
  );
}

export default Form;
