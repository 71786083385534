import React, { useEffect, useState } from "react";
import FlightTakeoffRoundedIcon from "@mui/icons-material/FlightTakeoffRounded";
import ScheduleSendRoundedIcon from "@mui/icons-material/ScheduleSendRounded";
import GavelRoundedIcon from "@mui/icons-material/GavelRounded";
import AcUnitRoundedIcon from "@mui/icons-material/AcUnitRounded";
import WarehouseRoundedIcon from "@mui/icons-material/WarehouseRounded";
import CommuteRoundedIcon from "@mui/icons-material/CommuteRounded";
import DirectionsBoatRoundedIcon from "@mui/icons-material/DirectionsBoatRounded";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Inquire from "./Inquire";
import useIntersect from "./util/useIntersect";
import Modal from "./Modal";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 680 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 680, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const Services = () => {
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-50% 0% -50% 0%",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document.querySelectorAll(".nav-link").forEach((i) => {
          i.classList.remove("active");
        });
        document.getElementById("nav-services").classList.add("active");
        // setPosition1("services");
        // document.getElementById("service-title").classList.add("show-line");
      }
    }
    // eslint-disable-next-line
  }, [entry.isIntersecting]);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({ state: false, value: null });
  const [type, setType] = useState(null);
  const [service, setService] = useState(null);
  const [shipment, setShipment] = useState(null);

  return (
    <div className="services" id="services" ref={ref}>
      <h2 className="section-title">Seamless Air & Sea Solutions</h2>
      <p className="services-paragraph">
        Explore our comprehensive services tailored to streamline your logistics
        journey from start to finish:
      </p>
      <br />
      <br />
      <div className="services-container-2">
        <div className="service">
          <div className="service-title">
            <FlightTakeoffRoundedIcon
              fontSize="2.3rem"
              className="service-icon"
            />
            <h4 className="service-title-text">Air Freight Solutions</h4>
          </div>
          <div className="service-bold">
            Swift, Secure, and Specialized air cargo services
          </div>
          <p className="service-desc">
            As a leading freight forwarder, Sahara Winds Ltd offers reliable air
            cargo capacity through strategic partnerships with airlines. We
            facilitate and secure block space agreements with major airlines to
            ensure your cargo has dedicated and guaranteed capacity to ensure
            timely departure, Sahara winds air freight solutions guarantee
            secure, cost-effective, and timely delivery for all your
            international freight requirements. We offer first mile logistics
            and we have partnered with forwarders on our international network
            to support last mile logistics.
          </p>
          <button
            className="service-cta"
            onClick={() => {
              setType("inquiry");
              setService("Air Freight Solutions");
              setOpen(true);
            }}
          >
            Inquire Now
          </button>
        </div>
        <div className="service even">
          <div className="service-title">
            <DirectionsBoatRoundedIcon
              fontSize="2.3rem"
              className="service-icon"
            />
            <h4 className="service-title-text">Sea Freight Solutions</h4>
          </div>
          <div className="service-bold">Cost-Efficient, Global Shipping</div>
          <p className="service-desc">
            Our sea freight services provide dependable, efficient shipping
            solutions through partnerships with leading shipping lines. Whether
            for imports or exports, we navigate international trade routes to
            deliver your cargo securely, offering end-to-end support. We offer
            first mile logistics to coordinate cargo pick-up, stuffing and
            through our network partners we offer last miles delivery logistics
            to ensure seamless end to end logistics.
          </p>
          <button
            className="service-cta"
            onClick={() => {
              setType("inquiry");
              setService("Sea Freight Solutions");
              setOpen(true);
            }}
          >
            Inquire Now
          </button>
        </div>
      </div>
      <div className="services-container">
        <div className="service">
          <div className="service-title">
            <ScheduleSendRoundedIcon
              fontSize="2.3rem"
              className="service-icon"
            />
            <h4 className="service-title-text">Charter Air Freight Services</h4>
          </div>
          <div className="service-bold">Urgent Deliveries on Your Schedule</div>
          <p className="service-desc">
            For critical, time-sensitive shipments, our full charter air freight
            service offers dedicated air transport. We arrange dedicated
            aircraft charters with direct routes and customized schedules,
            ensuring swift, dependable transit for your urgent deliveries.
          </p>
          <button
            className="service-cta"
            onClick={() => {
              setType("inquiry");
              setService("Charter Air Freight Services");
              setOpen(true);
            }}
          >
            Inquire Now
          </button>
        </div>
        <div className="service even">
          <div className="service-title">
            <GavelRoundedIcon fontSize="2.3rem" className="service-icon" />
            <h4 className="service-title-text">
              Customs Clearance for Imports and Exports
            </h4>
          </div>
          <div className="service-bold">
            Expert Navigation Through Regulations
          </div>
          <p className="service-desc">
            Leave the complexities of customs management to Sahara Winds Ltd.
            Our experienced team handles the entire customs process, from
            securing permits and validating imports & Exports certificates to
            managing tax declarations and ensuring full compliance for custom
            clearance of imports and exports shipments. Additionally, we offer
            specialized consultancy on tax and compliance, guiding clients
            through applicable tax regimes, HS codes, and customs tariffs before
            consignments arrive or departs Kenya—ensuring a seamless import or
            export process every time.
          </p>
          <button
            className="service-cta"
            onClick={() => {
              setType("inquiry");
              setService("Customs Clearance");
              setOpen(true);
            }}
          >
            Inquire Now
          </button>
        </div>
        <div className="service">
          <div className="service-title">
            <AcUnitRoundedIcon fontSize="2.3rem" className="service-icon" />
            <h4 className="service-title-text">
              Cold Chain Logistics for Perishables
            </h4>
          </div>
          <div className="service-bold">
            Preserving Quality for Temperature-Sensitive Cargo
          </div>
          <p className="service-desc">
            For perishables such as flowers, fruits, seafood, and meats, Sahara
            winds advanced cold chain solutions ensure strict temperature
            control from origin to destination, preserving freshness and quality
            at every stage.
          </p>
          <button
            className="service-cta"
            onClick={() => {
              setType("inquiry");
              setService("Cold Chain Management");
              setOpen(true);
            }}
          >
            Inquire Now
          </button>
        </div>
      </div>
      <div className="services-container-2">
        <div className="service even">
          <div className="service-title">
            <WarehouseRoundedIcon fontSize="2.3rem" className="service-icon" />
            <h4 className="service-title-text">
              Warehousing and Cargo Consolidation
            </h4>
          </div>
          <div className="service-bold">Efficient Storage and Cost Savings</div>
          <p className="service-desc">
            We offer secure warehousing, cold storage, and custom packaging
            services. From consolidating cargo for export to deconsolidating
            imports, Sahara winds solutions optimize space and reduce costs,
            enhancing logistics efficiency.
          </p>
          <button
            className="service-cta"
            onClick={() => {
              setType("inquiry");
              setService("Warehousing");
              setOpen(true);
            }}
          >
            Inquire Now
          </button>
        </div>
        <div className="service">
          <div className="service-title">
            <CommuteRoundedIcon fontSize="2.3rem" className="service-icon" />
            <h4 className="service-title-text">Road and Railway Transport</h4>
          </div>
          <div className="service-bold">
            Reliable Inland Transport Across Kenya and Beyond
          </div>
          <p className="service-desc">
            Sahara winds comprehensive road and railway transport services
            ensure goods reach their final destinations efficiently across Kenya
            and the broader East Africa region. We prioritize timely delivery
            and secure handling for every shipment.
          </p>
          <button
            className="service-cta"
            onClick={() => {
              setType("inquiry");
              setService("Road and Railway Transport");
              setOpen(true);
            }}
          >
            Inquire Now
          </button>
        </div>
      </div>
      <h2 className="section-title-2">
        Leading Experts in Shipping Perishable Cargo Shipments
      </h2>

      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
        // autoPlaySpeed={1000}
        centerMode={true}
        keyBoardControl={true}
        customTransition="ease .5"
        transitionDuration={100}
        containerClass="carousel-container"
        // removeArrowOnDeviceType={["tablet", "mobile"]}
        // deviceType={this.props.deviceType}
        // dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        className="carousel"
      >
        <div className="item mango">
          <div className="cargo-title">
            <b>Kenyan Mango</b>
          </div>
          <button
            className="cta-item"
            onClick={() => {
              setType("shipment");
              setShipment("Mango");
              setOpen(true);
            }}
          >
            Book Shipment
          </button>
        </div>
        <div className="item avocado">
          <div className="cargo-title">
            <b>Kenyan Avocado</b>
          </div>
          <button
            className="cta-item"
            onClick={() => {
              setType("shipment");
              setShipment("Avocado");
              setOpen(true);
            }}
          >
            Book Shipment
          </button>
        </div>
        <div className="item pineapple">
          <div className="cargo-title">
            <b>Kenyan Pineapple</b>
          </div>
          <button
            className="cta-item"
            onClick={() => {
              setType("shipment");
              setShipment("Pineapple");
              setOpen(true);
            }}
          >
            Book Shipment
          </button>
        </div>
        <div className="item veg">
          <div className="cargo-title">
            <b>Kenyan Vegetables</b>
          </div>
          <button
            className="cta-item"
            onClick={() => {
              setType("shipment");
              setShipment("Vegetables");
              setOpen(true);
            }}
          >
            Book Shipment
          </button>
        </div>
        <div className="item flowers">
          <div className="cargo-title">
            <b>Kenyan Flowers</b>
          </div>
          <button
            className="cta-item"
            onClick={() => {
              setType("shipment");
              setShipment("Flowers");
              setOpen(true);
            }}
          >
            Book Shipment
          </button>
        </div>
        <div className="item meat larger">
          <div className="cargo-title">
            <b>Goat & Lamp Meat</b>
          </div>
          <button
            className="cta-item"
            onClick={() => {
              setType("shipment");
              setShipment("Meat");
              setOpen(true);
            }}
          >
            Book Shipment
          </button>
        </div>
      </Carousel>
      <h2 className="section-title-2">
        Trusted Leaders in Shipping General Cargo Shipments
      </h2>

      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
        // autoPlaySpeed={1000}
        centerMode={true}
        keyBoardControl={true}
        customTransition="ease .5"
        transitionDuration={100}
        containerClass="carousel-container"
        // removeArrowOnDeviceType={["tablet", "mobile"]}
        // deviceType={this.props.deviceType}
        // dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        className="carousel"
      >
        <div
          className="item dgr"
          onClick={() =>
            setModal({ state: true, value: "Dangerous Goods (DGR Cargo)" })
          }
        >
          <div className="cargo-title">
            <b>Dangerous Goods</b>
          </div>
          <button
            className="cta-item"
            onClick={() => {
              setType("shipment");
              setShipment("Dangerous Goods (DGR Cargo)");
              setOpen(true);
            }}
          >
            Book Shipment
          </button>
        </div>
        <div
          className="item pharma"
          onClick={() =>
            setModal({ state: true, value: "Pharma & Healthcare" })
          }
        >
          <div className="cargo-title">
            <b>Pharma & Healthcare</b>
          </div>
          <button
            className="cta-item"
            onClick={() => {
              setType("shipment");
              setShipment("Pharma & Healthcare");
              setOpen(true);
            }}
          >
            Book Shipment
          </button>
        </div>
        <div
          className="item hs"
          onClick={() =>
            setModal({ state: true, value: "Humanitarian Support Logistics" })
          }
        >
          <div className="cargo-title">
            <b>Humanitarian Support</b>
          </div>
          <button
            className="cta-item"
            onClick={() => {
              setType("shipment");
              setShipment("Humanitarian Support Logistics");
              setOpen(true);
            }}
          >
            Book Shipment
          </button>
        </div>
        <div
          className="item me"
          onClick={() =>
            setModal({ state: true, value: "Machinery & Equipment" })
          }
        >
          <div className="cargo-title">
            <b>Machinery & Equipment</b>
          </div>
          <button
            className="cta-item"
            onClick={() => {
              setType("shipment");
              setShipment("Machinery & Equipment");
              setOpen(true);
            }}
          >
            Book Shipment
          </button>
        </div>
        <div
          className="item cts"
          onClick={() =>
            setModal({ state: true, value: "Critical Time Shipments" })
          }
        >
          <div className="cargo-title">
            <b>Critical Time Shipment</b>
          </div>
          <button
            className="cta-item"
            onClick={() => {
              setType("shipment");
              setShipment("Critical Time Shipments");
              setOpen(true);
            }}
          >
            Book Shipment
          </button>
        </div>
        <div
          className="item fashion"
          onClick={() =>
            setModal({
              state: true,
              value: "Sahara winds - Fashion & Textile Shipments",
            })
          }
        >
          <div className="cargo-title">
            <b>Fashion & Textile</b>
          </div>
          <button
            className="cta-item"
            onClick={() => {
              setType("shipment");
              setShipment("Fashion & Textile Shipments");
              setOpen(true);
            }}
          >
            Book Shipment
          </button>
        </div>
        <div
          className="item animal"
          onClick={() =>
            setModal({
              state: true,
              value: "Sahara winds - Live Animals & Live Seafood",
            })
          }
        >
          <div className="cargo-title">
            <b>Live Animals</b>
          </div>
          <button
            className="cta-item"
            onClick={() => {
              setType("shipment");
              setShipment("Live Animals & Live Seafood");
              setOpen(true);
            }}
          >
            Book Shipment
          </button>
        </div>
      </Carousel>
      <Inquire
        setOpen={setOpen}
        open={open}
        type={type}
        service={service}
        shipment={shipment}
      />
      <Modal modal={modal} setModal={setModal} />
    </div>
  );
};

export default Services;
