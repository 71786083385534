import React, { useState } from "react";
import { Link } from "react-scroll";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import FlightTakeoffRoundedIcon from "@mui/icons-material/FlightTakeoffRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import PermContactCalendarRoundedIcon from "@mui/icons-material/PermContactCalendarRounded";

const Navmob = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <div
      id="circularMenu1"
      className={`circular-menu circular-menu-left ${
        isActive ? "active1" : ""
      }`}
      style={{ zIndex: 110 }}
    >
      <button
        className="floating-btn"
        onClick={toggleMenu}
        style={{ zIndex: 100 }}
      >
        <MenuRoundedIcon style={{ color: "#426f8c" }} />
      </button>

      <menu className="items-wrapper" style={{ zIndex: 100 }}>
        <Link
          to="home"
          smooth="easeInQuad"
          offset={-100}
          duration={500}
          id="nav-home"
          isDynamic={true}
          className="menu-item"
        >
          <HomeRoundedIcon />
        </Link>
        <Link
          to="services"
          smooth="easeInQuad"
          offset={-50}
          duration={500}
          id="nav-home"
          isDynamic={true}
          className="menu-item"
        >
          <FlightTakeoffRoundedIcon />
        </Link>
        <Link
          to="about"
          smooth="easeInQuad"
          offset={-50}
          duration={500}
          id="nav-home"
          isDynamic={true}
          className="menu-item"
        >
          <QuestionMarkRoundedIcon />
        </Link>
        <Link
          to="contact"
          smooth="easeInQuad"
          offset={-50}
          duration={500}
          id="nav-home"
          isDynamic={true}
          className="menu-item"
        >
          <PermContactCalendarRoundedIcon />
        </Link>
      </menu>
    </div>
  );
};

export default Navmob;
