import { Fragment, useEffect, useState } from "react";
import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Nav from "./components/Nav";
import Navmob from "./components/Navmob";
import Pre from "./components/Pre";
import Services from "./components/Services";

function App() {
  const [loading, setLoading] = useState(true);

  // const handlePreloaderEnd = () => {
  //   setLoading(false);
  //   document.body.style.overflow = "auto"; // Re-enable scrolling
  // };
  useEffect(() => {
    // Set a timer for the preloader duration
    const timer = setTimeout(() => {
      setLoading(false); // Hide the preloader after 3 seconds
    }, 4000); // 3000ms = 3 seconds

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="App">
      {loading ? (
        <Pre />
      ) : (
        <Fragment>
          <Nav />
          <Navmob />
          <Home />
          <Services />
          <About />
          <Contact />
        </Fragment>
      )}
    </div>
  );
}

export default App;
