import React, { useEffect, useState } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

// import Form from "./Form";
const Modal = ({ modal, setModal }) => {
  const values = [
    {
      title: "Dangerous Goods (DGR Cargo)",
      img: null,
      text: "We provide specialized handling for hazardous materials and regulated DGR cargo shipments, ensuring compliance with international safety standards and DGR cargo protocols. Our experienced team guarantees secure transportation for DGR goods such as chemicals, flammable materials, and industrial substances.",
    },
    {
      title: "Pharma & Healthcare",
      img: null,
      text: "Leveraging state-of-the-art temperature-controlled logistics, we ensure the safe and timely delivery of critical healthcare shipments, including vaccines, biological samples, and medical products. We are committed to maintaining the integrity of healthcare shipments vital to saving lives.",
    },
    {
      title: "Humanitarian Support Logistics",
      img: null,
      text: "Focused on humanitarian aid, our logistics solutions provide rapid responses for emergency relief efforts. We efficiently handle essential supplies, food aid, and disaster recovery materials shipments to support communities in distress.",
    },
    {
      title: "Machinery & Equipment",
      img: null,
      text: "Sahara Winds Ltd specializes in the transportation of heavy and intricate equipment critical to industries such as agriculture, manufacturing, mining, oil, and gas. Whether it’s oversized machinery or precision tools, we ensure safe and timely delivery, even to remote locations.",
    },
    {
      title: "Critical Time Shipments",
      img: null,
      text: "Designed for urgent, time-sensitive deliveries, our critical time services are tailored for industries where every moment matters, such as aviation and marine operations. We ensure urgent, time-sensitive deliveries of essential materials and spare parts, enabling seamless operations and reducing downtime. With streamlined processes, dedicated resources, and an unwavering commitment to punctuality, Sahara Winds Ltd minimizes delays, delivering your critical time shipments exactly when and where they’re needed",
    },
    {
      title: "Sahara winds - Fashion & Textile Shipments",
      img: null,
      text: "Supporting the dynamic fashion and textile industries, we offer reliable logistics for garments, fabrics, and accessories. Our services cater to seasonal demands, ensuring timely delivery in line with fast-paced retail schedules.",
    },
    {
      title: "Sahara winds - Live Animals & Live Seafood",
      img: null,
      text: "     Sahara Winds Ltd offers specialized logistics solutions for the transportation of domestic pets, livestock, and live seafood. We prioritize the health and safety of living cargo by adhering to international standards and live animal transport protocols. Our expert team ensures regulated handling, proper ventilation, and humane practices throughout the journey, guaranteeing the well-being of animals and compliance with all regulatory requirements. Whether it’s pets, farm animals, or seafood, we deliver with care and reliability.",
    },
  ];
  //   console.log(produce.filter((item) => item.selected));
  //   const [s, setS] = useState([]);
  //   const c = [
  //     "Avocado",
  //     "Mango",
  //     "Pineapple",
  //     "Banana",
  //     "Passion Fruit",
  //     "Custard Apple",
  //     "Coffee",
  //     "Ginger",
  //     "Flowers",
  //     "Veggies",
  //   ];

  //   useEffect(() => {
  //     if (service) {
  //     //   setS(service.filter((item) => item.selected));
  //       console.log(s);
  //     }
  //   }, [service]);
  return (
    <div className={`modal-container ${!modal.state && "hide-modal"}`}>
      <div style={{ justifyItems: "center" }} className={`modal`}>
        <div
          onClick={() => setModal({ state: !modal.state, value: null })}
          style={{ zIndex: 999999999999999 }}
        ></div>
        <div className="mx-w form-container">
          <CancelRoundedIcon
            className="close"
            fontSize=""
            onClick={() => {
              setModal({ state: !modal.state, value: null });
            }}
          />
          <h4 className="section-title black center">
            {values.filter((i) => i.title === modal.value)[0]?.title}
          </h4>
          <br />
          <p className="about-s1">
            {values.filter((i) => i.title === modal.value)[0]?.text}
          </p>
        </div>
      </div>
      <div
        className={`modal-overlay ${!modal.state && "hide-overlay"}`}
        onClick={() => setModal({ state: false, value: null })}
      ></div>
    </div>
  );
};

export default Modal;
