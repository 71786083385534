import React, { Fragment, useEffect } from "react";
import Form from "./Form";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import useIntersect from "./util/useIntersect";

function Contact() {
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "0% 0% 0% 0%",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document.querySelectorAll(".nav-link").forEach((i) => {
          i.classList.remove("active");
        });
        document.getElementById("nav-contact").classList.add("active");
        // setPosition1("services");
        // document.getElementById("service-title").classList.add("show-line");
      }
    }
    // eslint-disable-next-line
  }, [entry.isIntersecting]);
  return (
    <Fragment>
      <div className="contact" id="contact" ref={ref}>
        <Form />
        <div className="faq-container">
          <h4 className="section-title black">Frequently Asked Questions</h4>
          <div className="faq">
            <div className="faq-s">
              <span className="q">
                What types of cargo does Sahara Winds handle?
              </span>
              <span className="a">
                We specialize in handling a wide range of cargo, including
                perishables like flowers, fruits, vegetables, seafood, and
                foodstuffs. Our team has the expertise and facilities to ensure
                your cargo remains in optimal condition from origin to
                destination.
              </span>
            </div>
            <div className="faq-s">
              <span className="q">
                How do you handle customs and trade compliance for international
                shipments?
              </span>
              <span className="a">
                Our experienced team manages all customs documentation and
                provides guidance on trade compliance, including certifications
                like phytosanitary and CTPAT, as well as adherence to trade
                protocols like AGOA, COMESA, and EU trade agreements.
              </span>
            </div>
            <div className="faq-s">
              <span className="q">
                How can I get a quote or book a shipment with Sahara Winds?
              </span>
              <span className="a">
                Getting a quote or booking a shipment is easy! Simply reach out
                through our contact form, email, or call us directly. Our team
                will provide a customized quote and guide you through the
                booking process based on your specific needs.
              </span>
            </div>
            <div className="faq-s">
              <span className="q">What geographic regions do you cover?</span>
              <span className="a">
                We operate both locally within East Africa and internationally.
                Thanks to our network of global partners, we provide
                door-to-door delivery options worldwide and maintain space
                protection agreements with major airlines and shipping lines.
              </span>
            </div>
            <div className="faq-s">
              <span className="q">
                Can you manage both air and sea shipments?
              </span>
              <span className="a">
                Yes, Sahara Winds offers comprehensive air and sea logistics
                solutions. We handle everything from documentation and cargo
                consolidation to customs clearance, ensuring a smooth and
                reliable experience for imports and exports.
              </span>
            </div>
            <div className="faq-s">
              <span className="q">
                How does Sahara Winds ensure the quality of perishable goods
                during transit?
              </span>
              <span className="a">
                We use advanced cold chain solutions, including
                temperature-controlled packaging, warehousing, and transport, to
                maintain the freshness and safety of perishable items.
              </span>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="details">
        <div className="aad">
          <div>
            <div className="wrap">
              <AlternateEmailRoundedIcon />
              <span>commercial@saharawinds.ke</span>
            </div>
            <div className="wrap">
              <LocalPhoneRoundedIcon />
              <span>+254 704 936 937</span>
            </div>
          </div>
          <div className="wrap">
            <LocationOnRoundedIcon />
            <span>
              Jomo kenyatta International Airport, Freight Terminal 1 building,
              Freight Terminal Road, Office 105. Box 52203 postal code 00100,
              Nairobi Kenya
            </span>
          </div>
        </div>
        <div className="social">
          <a href="https://wa.me/254704936937" target="_blank">
            <WhatsAppIcon fontSize="" className="social-icon" />
          </a>
          <a
            href="https://ke.linkedin.com/in/sahara-winds-ltd-bba4b0198"
            target="_blank"
          >
            <LinkedInIcon fontSize="" className="social-icon" />
          </a>
        </div>
      </div>
    </Fragment>
  );
}

export default Contact;
