import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-scroll";
import Inquire from "./Inquire";
import useIntersect from "./util/useIntersect";
import img from "../images/113094-OOR2CK-829-ai.png";

function Home() {
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-50% 0% -50% 0%",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document.querySelectorAll(".nav-link").forEach((i) => {
          i.classList.remove("active");
        });
        document.getElementById("nav-home").classList.add("active");
        // setPosition1("services");
        // document.getElementById("service-title").classList.add("show-line");
      }
    }
    // eslint-disable-next-line
  }, [entry.isIntersecting]);
  const [open, setOpen] = useState(false);
  const type = "inquiry";
  return (
    <Fragment>
      <div className="home" id="home" ref={ref}>
        <div className="plane-home"></div>
        <div className="headline">
          <h1 className="headline-text">
            Your <span id="headline-underline">Gateway</span> to Global Trade.
          </h1>
        </div>
        <div className="cta-home">
          <Link
            to="service"
            smooth="easeInQuad"
            offset={0}
            duration={500}
            className="cta-one-home"
            id="cta-one-home"
            isDynamic={true}
          >
            Explore Our Expertise
          </Link>
          <button
            className="cta-two-home"
            onClick={() => {
              setOpen(true);
            }}
          >
            Get Solutions Now
          </button>
          <p className="hero-paragraph-mob">
            At Sahara Winds Ltd, we empower businesses to expand globally with
            secure, seamless, and cost-effective logistics solutions. From Kenya
            to destinations worldwide, trust our team of logistics experts to
            navigate your unique requirements and ensure timely delivery of
            every consignment.
          </p>
        </div>
        <div className="ship-home">
          <p className="hero-paragraph">
            At Sahara Winds Ltd, we empower businesses to expand globally with
            secure, seamless, and cost-effective logistics solutions. From Kenya
            to destinations worldwide, trust our team of logistics experts to
            navigate your unique requirements and ensure timely delivery of
            every consignment.
          </p>
        </div>
        <Inquire setOpen={setOpen} open={open} type={type} />
      </div>
      <div className="cta-div">
        <h2 className="cta-text">Empowering Kenya’s Connection to the World</h2>
        <img src={img} alt="" className="cta-img" />
      </div>
    </Fragment>
  );
}

export default Home;
