import React, { useEffect, useState } from "react";

import Inquire from "./Inquire";
// import logo from "../../images/logo.svg";
import { Link } from "react-scroll";
const Nav = () => {
  const [shrink, setShrink] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [window]);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setShrink(true);
    } else {
      setShrink(false);
    }
  };

  const [open, setOpen] = useState(false);
  const type = "inquiry";

  return (
    <nav id="nav-bar" className={`nav-bar ${shrink && "shrink"}`}>
      {/* <img src={logo} alt="namaa" className="nav-logo" /> */}
      <h2 className="nav-logo">Sahara Winds</h2>
      <ul className="nav-list">
        <li className="nav-item ">
          <Link
            to="home"
            smooth="easeInQuad"
            offset={0}
            duration={500}
            className="nav-link active"
            id="nav-home"
            isDynamic={true}
          >
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="services"
            smooth="easeInQuad"
            offset={-100}
            duration={500}
            className="nav-link"
            id="nav-services"
            isDynamic={true}
          >
            Services
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="about"
            smooth="easeInQuad"
            offset={-100}
            duration={500}
            className="nav-link"
            id="nav-about"
            isDynamic={true}
          >
            About Us
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="contact"
            smooth="easeInQuad"
            offset={0}
            duration={500}
            className="nav-link"
            id="nav-contact"
            isDynamic={true}
          >
            Contact
          </Link>
        </li>
      </ul>
      <button
        className="nav-cta"
        onClick={() => {
          setOpen(true);
        }}
      >
        Get in touch
      </button>
      <Inquire setOpen={setOpen} open={open} type={type} />
    </nav>
  );
};

export default Nav;
