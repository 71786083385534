import React, { useEffect } from "react";
import useIntersect from "./util/useIntersect";

function About() {
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-50% 0% -50% 0%",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document.querySelectorAll(".nav-link").forEach((i) => {
          i.classList.remove("active");
        });
        document.getElementById("nav-about").classList.add("active");
        // setPosition1("services");
        // document.getElementById("service-title").classList.add("show-line");
      }
    }
    // eslint-disable-next-line
  }, [entry.isIntersecting]);
  return (
    <div className="about" id="about" ref={ref}>
      <h2 className="about-section-title">
        Sahara Winds: <span className="orange">Expertise You Can Trust</span>
      </h2>
      <div className="about-sec">
        <p className="about-s1">
          <b>
            Founded in 2018, Sahara Winds Ltd is a Kenyan freight forwarder and
            licensed customs brokerage firm dedicated to providing logistics
            services connecting Kenyan businesses to the global marketplace.
          </b>
          <br />
          <br /> Our in-house customs brokerage is fully licensed by the Kenya
          Revenue Authority (KRA) and Kentrade, allowing us to manage customs
          clearance with full compliance for both export and import shipments.
          Sahara winds Ltd focuses on efficient, tailored logistics solutions,
          Sahara Winds Ltd bridges the gap between local trade and international
          markets.
          <br />
          <br />{" "}
          <span className="orange">
            <b>Mission Statement</b>
          </span>
          <br />
          Our mission is to drive business growth by delivering dependable,
          efficient, and customized logistics solutions that connect Kenyan
          trade to international markets. With a commitment to integrity,
          regulatory compliance, and innovation, we strive to support our
          clients’ supply chains and help them thrive in an international trade
          interconnected world.
          <br />
          {/* <br />
          <br />
          <div className="about-image-2"></div> */}
          {/* <br /> */}
          <br />
          <span className="orange">
            <b>Why Choose Sahara Winds?</b>
          </span>
          <br />
          <b>Experienced Leadership:</b> Our team of logistics consultants
          brings extensive experience, supported by a global network of freight
          forwarding partners. We deliver expertise in every aspect of
          logistics. <br />
          <br />
          <b>Regulatory Compliance:</b> We adhere to industry protocols like
          AGOA, COMESA, and EU agreements, along with specific certifications
          such as phytosanitary and port health requirements, ensuring
          compliance and peace of mind.
          <br />
          <br /> <b>Customized Solutions:</b> We offer solutions tailored to
          your cargo's needs, from temperature-controlled storage to specialized
          packaging, adapting to the unique demands of each shipment.
          <br />
          <br />
          <span className="orange">
            <b>Memberships</b>
          </span>
          <br />
          As a proud member of the Kenya International Freight Forwarders &
          Warehousing Association (KIFWA), we uphold industry standards and best
          practices, ensuring excellence in all our services.
        </p>
        <div className="about-image"></div>
      </div>
      <div className="services-container margin-top">
        <div className="service auto">
          <div className="service-title">
            <h4 className="service-title-text full-center">
              Trade Compliance & Documentation
            </h4>
          </div>
          <div className="service-bold">
            Streamlining Documentation for Smooth, Compliant Trade
          </div>
          <p className="service-desc">
            Navigating regulatory requirements can be challenging. At Sahara
            Winds Ltd, we manage complex export and import documentation,
            ensuring all essential paperwork—such as certificates of origin,
            phytosanitary certificates, and tax declarations—is in order. From
            export declarations to import permits, we help ensure seamless,
            compliant cross-border transactions.
          </p>
        </div>
        <div className="service auto">
          <div className="service-title">
            <h4 className="service-title-text full-center">
              Cold Chain Integrity Commitment
            </h4>
          </div>
          <div className="service-bold">Safeguarding Perishable Shipments</div>
          <p className="service-desc">
            Perishable goods demand a secure cold chain system to preserve
            quality. With refrigerated containers, temperature-controlled
            warehouses, and real-time monitoring tools like data loggers and
            RFID tags, we maintain optimal conditions for your cargo, ensuring
            it reaches its destination fresh and intact.
          </p>
        </div>
        <div className="service auto">
          <div className="service-title">
            <h4 className="service-title-text full-center">
              Export and Import Customs Clearance
            </h4>
          </div>
          <div className="service-bold">
            Efficient Clearance for Seamless Trade
          </div>
          <p className="service-desc">
            Our skilled customs team helps you navigate evolving regulations to
            prevent delays and minimize storage fees. With a deep understanding
            of export and import procedures, we process essential paperwork
            directly with customs authorities to keep your cargo on schedule.
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="about-image-x"></div>
    </div>
  );
}

export default About;
