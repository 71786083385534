import React, { useEffect } from "react";
import AirplanemodeActiveRoundedIcon from "@mui/icons-material/AirplanemodeActiveRounded";
// import "./PlanePreloader.css";

const Pre = ({ onAnimationEnd }) => {
  const timer = setTimeout(() => {
    const preloader = document.querySelector(".preloader");
    preloader.classList.add("slide-up");

    // Call onAnimationEnd after the slide-up transition
  }, 3000);

  return (
    <div className={`preloader`}>
      <div className="plane">
        <AirplanemodeActiveRoundedIcon fontSize="" />
      </div>
      <div className="dashed-line"></div>
    </div>
  );
};

export default Pre;
