import React, { useEffect, useState } from "react";
import Form from "./Form";
const Inquire = ({ open, setOpen, service, type, shipment }) => {
  //   console.log(produce.filter((item) => item.selected));
  //   const [s, setS] = useState([]);
  //   const c = [
  //     "Avocado",
  //     "Mango",
  //     "Pineapple",
  //     "Banana",
  //     "Passion Fruit",
  //     "Custard Apple",
  //     "Coffee",
  //     "Ginger",
  //     "Flowers",
  //     "Veggies",
  //   ];

  //   useEffect(() => {
  //     if (service) {
  //     //   setS(service.filter((item) => item.selected));
  //       console.log(s);
  //     }
  //   }, [service]);
  return (
    <div className={`modal-container ${!open && "hide-modal"}`}>
      <div className={`modal`}>
        <div
          onClick={() => setOpen(!open)}
          style={{ zIndex: 999999999999999 }}
        ></div>
        <Form
          type={type}
          service={service}
          shipment={shipment}
          setOpen={setOpen}
        />
      </div>
      <div
        className={`modal-overlay ${!open && "hide-overlay"}`}
        onClick={() => setOpen(!open)}
      ></div>
    </div>
  );
};

export default Inquire;
